<template>
  <Page class="bank-page" :loading="loading" :empty="cards.length === 0">
    <Navbar title="我的银行卡">
      <template #right>
        <span v-if="cards.length > 0" class="text_primary" @click="() => handleJump(PageEnum.BANK_CHANGE_DEFAULT)">
          修改默认
        </span>
      </template>
    </Navbar>
    <div class="bank_cont bg-white px-24" style="min-height: 100%">
      <div class="pt-24" v-if="cards.length !== 0">
        <BankCard
          v-for="(item, index) in cards"
          :key="index"
          :cardData="{
            ...item,
            ...getBankObj(item.bankCode)
          }"
          v-on:handleUnbindBank="handleUnbindBank(item, index)"
        />
      </div>
      <div
        class="w-full h-auto flex justify-center items-center"
        v-if="cards.length !== 0"
        @click="handleJump(PageEnum.BANK_ADD)"
      >
        <div class="w-full btn_class bg-white flex justify-center items-center rounded-4">
          <div class="flex justify-start items-center py-12 text-primary">
            <!-- <div class="btn_icon"><span class="btn_icon_icon">+</span></div> -->
            <Icon name="icon_upload" class="text-18" />
            <div class="ml-12">添加银行卡</div>
          </div>
        </div>
      </div>
    </div>
    <template #empty>
      <!-- <div><img :src="require('@/assets/nobank.jpg')" /></div>
      <p class="mt-16">{{ '暂无银行卡' }}</p>
      <Button @click="handleJump(PageEnum.BANK_ADD)" class="mt-42" size="normal">添加银行卡</Button> -->
      <div class="flex flex-col justify-center items-center empty-tips">
        <div>
          <div class="flex flex-col items-center p-32">
            <img
              class="w-134 h-134"
              src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/chatu_weibangdingyinhangka%403x.png"
            />
            <span class="text-muted text-16">暂无银行卡，先去绑定一张吧</span>
          </div>
          <div class="w-full h-auto flex justify-center items-center" @click="handleJump(PageEnum.BANK_ADD)">
            <div class="w-full btn_class bg-white flex justify-center items-center rounded-4">
              <div class="flex justify-start items-center py-12 text-primary">
                <!-- <div class="btn_icon mr-12"><span class="btn_icon_icon">+</span></div> -->
                <Icon name="icon_upload" class="text-18" />
                <div class="ml-8">添加银行卡</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Page>
</template>
<script>
import { defineComponent, onBeforeMount, computed, ref } from '@vue/composition-api';
import { useRouter } from '@/utils/compsitionHack';
import BankCard from '@/components/BankCard/index.vue';
import { useBankStore } from '@/store/bank';
import { bankList } from '../mine/constants';
import { Button, Dialog, Toast } from 'vant';
import { PageEnum } from '@/enums/pageEnum';
import { apiUnbindBank } from '@/api/mine';
import { useUserStore } from '@/store/user';
export default defineComponent({
  name: 'Bank',
  components: {
    BankCard,
    Button
  },
  setup() {
    const bankStore = useBankStore();
    const cards = computed(() => bankStore.cards);
    const router = useRouter();
    const loading = ref(true);
    const userStore = useUserStore();
    const handleJump = url => {
      if (userStore.isDriver && !userStore?.driverAuthVO?.id) {
        Toast('请先完成司机认证');
        return;
      }
      router.push(url);
    };
    onBeforeMount(async () => {
      await bankStore.queryBankCards();
      loading.value = false;
    });

    const handleUnbindBank = (item, index) => {
      let payeeId = item.payeeId;
      Dialog.confirm({
        title: '',
        message: '是否确定解绑该银行卡'
      })
        .then(() => {
          // on confirm
          loading.value = true;
          apiUnbindBank({ payeeId })
            .then(async () => {
              try {
                bankStore.queryBankCards();
                loading.value = false;
              } catch (error) {
                console.log('err is ->', error);
              }
            })
            .error(error => {
              console.log('err is ->', error);
            });
        })
        .catch(() => {
          // on cancel
        });
    };

    const getBankObj = code => {
      if (!code || !bankList[code]) return bankList['DEFAULT'];
      return bankList[code];
    };
    return {
      cards,
      PageEnum,
      bankList,
      handleJump,
      loading,
      handleUnbindBank,
      getBankObj
    };
  }
});
</script>
<style lang="less" scoped>
.btn_class {
  border: 0.5px solid #0076ff;
}

.empty-tips {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
  margin-top: 30%;
}

.btn_icon {
  width: 0.21rem;
  height: 0.21rem;
  border-radius: 50%;
  border: 0.5px solid #0076ff;
  color: #0076ff;
  position: relative;
  .btn_icon_icon {
    position: absolute;
    top: 0rem;
    left: 0.055rem;
  }
}

.addBankCard-btn {
  width: 0.4rem;
  height: 0.4rem;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0);
  border-radius: 50%;
  color: white;
  margin: 0 auto;
  font-size: 0.4rem;
}

.add-card-btn {
  background: #ffffff;
  border: 1px solid #0076ff;
  border-radius: 0.04rem;
  width: 3.27rem;
  height: 0.48rem;
}
</style>
