<template>
  <div
    class="bank-card bda-8 mb-16 d-flex flex-column justify-space-between p-16 box-border"
    @click="handleChangeDefaultBank"
    :style="
      `background-image: linear-gradient(145deg, rgba(${cardData.backgroundColor}, 1), rgba(${cardData.backgroundColor}, 0.5))`
    "
  >
    <div class="d-flex">
      <div class="circle-white flex justify-center items-center">
        <img class="" :src="getFullBankIcon(cardData.filename)" alt="" />
      </div>
      <div class="d-flex justify-space-between" style="flex: 1">
        <div>
          <div class="mb-12">
            <span class="text-16 lh-20 mr-16">
              {{ cardData.bankName }}
            </span>
            <span v-if="cardData.defaultState === 1" class="text-10 default">默认卡</span>
          </div>
          <div class="font-14">
            <span class="mr-8" v-if="cardData.bankAccount">{{ cardData.bankAccount.slice(0, 4) }}</span>
            <span class="mr-8">****</span>
            <span class="mr-8">****</span>
            <span class="mr-8" v-if="cardData.bankAccount">{{ cardData.bankAccount.slice(-4) }}</span>
          </div>
        </div>
        <!-- <div class="d-flex font-16" v-if="cardData.defaultState === 1"><Icon name="yhkxz" svgClass="mr-8" /> 默认</div> -->
      </div>
      <div class="unbind" @click="handleUnbindBank">
        <img
          class="unbind-img"
          src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_app/yinhangkajiebang.png"
        />
        <span class="text-12">
          解绑
        </span>
      </div>
    </div>
    <!-- <div class="font-18">
      <span class="mr-24">****</span>
      <span class="mr-24">****</span>
      <span class="mr-24">****</span>
      <span class="mr-24" v-if="cardData.bankAccount">{{ cardData.bankAccount.slice(-4) }}</span>
    </div> -->
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { getFullBankIcon } from '@/views/mine/constants';

export default defineComponent({
  name: 'Carcard',
  props: {
    index: {
      type: Number
    },
    cardData: {
      type: Object
    }
  },

  setup(props, ctx) {
    const handleChangeDefaultBank = () => {
      ctx.emit('changeDfaultBank', props.index);
    };
    const handleUnbindBank = () => {
      ctx.emit('handleUnbindBank', props.index);
    };
    return {
      handleChangeDefaultBank,
      handleUnbindBank,
      getFullBankIcon
    };
  }
});
</script>
<style lang="less">
.bank-card {
  // height: 1.4rem;
  width: 100%;
  color: white;
  .circle-white {
    width: 0.52rem;
    // padding-top: 0.06rem;
    // padding-left: 0.06rem;
    height: 0.52rem;
    margin-right: 0.2rem;
    background-color: white;
    border-radius: 50%;
  }
  img {
    height: 0.35rem;
    width: 0.35rem;
  }
  .default {
    display: inline-block;
    width: 0.44rem;
    height: 0.2rem;
    line-height: 0.2rem;
    text-align: center;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 26px;
    opacity: 1;
  }
  .unbind {
    align-self: flex-start;
  }
  .unbind-img {
    width: 0.12rem;
    height: 0.12rem;
  }
}
</style>
